import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fb98fa8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "footer-mobile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Text = _resolveComponent("Text")!

  return (_ctx.isMobile)
    ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
        _createVNode(_component_Text, {
          title: "Copyright © Tayori .inc",
          color: "#8A8A8A",
          fontSize: "1.4rem",
          fontWeight: "400",
          lineHeight: "2.4rem"
        })
      ]))
    : _createCommentVNode("", true)
}