
import { defineComponent, inject } from 'vue';
import Text from '@/components/Text/index.vue';

export default defineComponent({
  name: 'Footer',
  components: { Text },
  props: {
    msg: String
  },
  setup() {
    const isMobile: any = inject('isMobile');

    return {
      isMobile
    };
  }
});
