import { createRouter, RouteRecordRaw, createWebHashHistory } from 'vue-router';
import MainLayout from '@/layout/Index.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: MainLayout,
    redirect: '/top',
    children: [
      {
        path: 'top',
        component: () => import('@/views/DashBoard.vue'),
        name: 'DashBoard',
        meta: {
          roles: []
        }
      },
      {
        path: 'contact',
        component: () => import('@/views/Contact.vue'),
        name: 'Contact',
        meta: {
          roles: []
        }
      },
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound.vue'),
        name: 'NotFound'
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound.vue'),
    name: 'NotFound'
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
