export const usePhone = () => {
  const phoneFormat = /^[0-9-]*$/g;
  const validPhone = (phone: string) => {
    // eslint-disable-next-line
    // @ts-ignore
    if (phone.match(phoneFormat)) {
      return true;
    } else {
      return false;
    }
  };

  return {
    validPhone
  };
};
