
import { useNavigate } from '@/composable';
import { defineComponent, computed, watch, ref, inject } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ButtonItem',
  props: {
    title: String,
    iconRsc: String,
    href: String,
    url: String,
    taget: String,
    disabled: Boolean,
    ticker: Boolean
  },
  setup(props) {
    const { navigate } = useNavigate();
    const { locale } = useI18n();
    const isMobile: any = inject('isMobile');
    const activeLang = ref(localStorage.getItem('locale'));
    const arrowIcon = computed(() => {
      return props.iconRsc || require('@/assets/img/nav/arrow.svg');
    });
    const taget = computed(() => {
      return props.taget || '_blank';
    });
    const onClick = () => {
      if (props.url) {
        navigate(props.url, taget.value === '_blank' ? 'window' : 'router');
      }
    };

    watch(
      locale,
      () => {
        activeLang.value = localStorage.getItem('locale');
      },
      { deep: true }
    );

    return {
      arrowIcon,
      onClick,
      activeLang,
      isMobile
    };
  }
});
