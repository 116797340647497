
import { defineComponent, reactive, ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useNavigate } from '@/composable';
import Button from '@/components/Button/index.vue';

interface NavItem {
  name: string;
  text: string;
  active: boolean;
}

export default defineComponent({
  name: 'NavBar',
  props: {},
  components: { Button },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { navigate } = useNavigate();
    const { t, locale } = useI18n();
    const activeLang = ref(localStorage.getItem('locale') || 'en-US');
    const isMobile: any = inject('isMobile');
    const nav = reactive<NavItem[]>([
      {
        name: 'about-us',
        text: 'top.nav-1',
        active: false
      },
      {
        name: 'services',
        text: 'top.nav-2',
        active: false
      },
      {
        name: 'industries',
        text: 'top.nav-3',
        active: false
      },
      // {
      //   name: 'news',
      //   text: 'top.nav-4',
      //   active: false
      // },
      // {
      //   name: 'menber',
      //   text: 'top.nav-5',
      //   active: false
      // },
      {
        name: 'company-information',
        text: 'top.nav-6',
        active: false
      }
      // {
      //   name: 'recruit',
      //   text: 'top.nav-7',
      //   active: false
      // }
    ]);
    const isShowMenu = ref(false);

    const goToHome = () => {
      isShowMenu.value = false;
      navigate('/top');
    };

    const goToContact = () => {
      isShowMenu.value = false;
      navigate('/contact');
    };

    const changeLangs = (val: string) => {
      locale.value = val;
      localStorage.setItem('locale', val);
      activeLang.value = val;
      isShowMenu.value = false;
    };

    const gotoTopTag = (val: string) => {
      isShowMenu.value = false;
      if (route.name === 'Contact') {
        navigate('/top', 'router', { tag: val });
      } else {
        router.push({
          name: route!.name || '',
          query: { tag: val }
        });
      }
    };

    return {
      nav,
      isShowMenu,
      navigate,
      goToHome,
      t,
      goToContact,
      changeLangs,
      activeLang,
      gotoTopTag,
      isMobile
    };
  }
});
