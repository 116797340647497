import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/ticker.svg'
import _imports_1 from '@/assets/Vector-sp.svg'


const _withScopeId = n => (_pushScopeId("data-v-4ec17821"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-item--top" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  class: "ticker"
}
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  src: _imports_1,
  alt: "vector-img",
  class: "button-item--top__icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['button-item', _ctx.disabled && 'disabled']),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.ticker)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("a", {
        class: _normalizeClass(["button-item--top__title", [_ctx.activeLang === 'ja-JP' && 'active-lang']]),
        href: _ctx.href
      }, _toDisplayString(_ctx.title), 11, _hoisted_3),
      (!_ctx.isMobile)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.arrowIcon,
            alt: "vector-img",
            class: "button-item--top__icon"
          }, null, 8, _hoisted_4))
        : (_openBlock(), _createElementBlock("img", _hoisted_5))
    ])
  ], 2))
}